<template>
  <v-container class="main-color">
      <div class="d-flex flex-column align-center">
        <h1>{{$t('aboutBact')}}</h1>
      </div>

      <v-form ref="form">
        <v-row justify-sm="center" align-content="center">
          <v-col cols-sm="12" cols-md="6">
            <v-subheader>{{$t('message')}}</v-subheader>
            <v-textarea 
            :rules="nameRules"
            style="min-width:350px" 
            full-width v-model="message">
            </v-textarea>
            <v-subheader>{{$t('image')}}</v-subheader>
              <v-file-input
                accept="image/*"
                :prepend-icon="cameraIcon"
                v-model="newImg"
              ></v-file-input>
            <v-row justify="center" width="100%">
              <base-button @click="saveAboutUs()" class="mt-6 mr-8" :options="{block :false , isLoading : false}">
                {{$t('form.save')}}
              </base-button>
              
              <base-button @click="clear()" class="mt-6 mr-8" :options="{block :false , isLoading : false, color: RColor}">
                {{$t('form.clear')}}
              </base-button>
            </v-row>
          </v-col>
          <v-col cols-sm="12" cols-md="6">
            <v-img
              :src="getImg"
             max-width="100%"
            ></v-img>
          </v-col>
        </v-row>
      </v-form>
      
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BaseButton from '@/core/Base/Buttons/BaseButton'

export default {
  name: 'EditAboutUs',

  components: {
    BaseButton,
  },

  data () {
    return {
      nameRules: [(v) => !!v || this.$t('required.name')],
      message: '',
      img: null,
      newImg: null,
    }
  }, 

  mounted () {
    this.getImgAndMsg()
    .then(() => {
      this.message = this.getAbout.message;
    //   this.img = this.getAbout.img;
    })
  },

  methods: {
    ...mapActions("AboutUsAdmin", ["getImgAndMsg", "updateAboutUs"]),

    saveAboutUs () {
      if (!this.$refs.form.validate()) return;
      this.updateAboutUs({ message: this.message, img: this.newImg })
    },
    clear () {
      this.message = '';
      this.newImg = null;
      this.img = null;
    },
  },

  computed: {
    ...mapGetters("AboutUsAdmin", ["getAbout"]),

    getImg() {
      if (this.newImg) {
        return URL.createObjectURL(this.newImg)
      } else {
        return `https://bact-back.usol.ca/${this.getAbout.img}`;
      }
    },
  }
}
</script>